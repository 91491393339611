const Base = "https://gestordeproyectos-api.azurewebsites.net";

export const createMatriz = async (datos) => {
    try {
        const response = await fetch(Base + '/MatrizV2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(datos),
        });

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error guardando la matriz:', error);
        throw error;
    }
};

export async function  GetAllMatriz() {
    try {
      const response = await fetch(Base+'/MatrizV2');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

export async function  getOneMatriz(id){
  try {
    const response = await fetch(Base+'/MatrizV2/' + id);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function  updateMatriz(id, datos){
    try {
      const response = await fetch(Base+'/MatrizV2/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}