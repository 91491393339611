import React, { useState } from 'react';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import perfil from '../images/perfil.jpg';
import logo from '../images/white_logofake.png';
import { Link } from 'react-router-dom'; 
import { LogoutButton } from './LogoutButton';
import { useAuth0 } from "@auth0/auth0-react";
import { ExitToApp } from '@mui/icons-material';
import Notificaciones from './Notificaciones';
import { Card, Typography, CardContent } from '@mui/material';

const drawerWidth = 240;

const MyIconButton = styled(IconButton)({
    marginLeft: '0px',
    marginRight: '0',
    padding: '0',
});

const MyAppBar = styled(AppBar)({
  backgroundColor: '#0067A0',
});

const MyDrawer = styled(Drawer)({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: '#f7f7f7', // Color de fondo de la barra lateral
    color: '#333', // Color del texto
  },
});

const MyToolbar = styled(Toolbar)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const CenteredLogo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
});

const MyDrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',
});

const MyContent = styled('main')({
  flexGrow: 1,
  padding: '33px',
  backgroundImage: 'linear-gradient(to right, red, #FF9E1B, red, #FF9E1B)',
  backgroundSize: '100% 3px',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
});

const Logo = styled('img')({
  maxWidth: '100%',
  maxHeight: '50px',
});

const PageName = styled('div')({
  marginLeft: '16px',
  color: 'white',
  fontWeight: 'bold',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0), // Añade margen entre los elementos de la lista
  borderRadius: '8px', // Añade bordes redondeados
  '&.Mui-selected': {
    backgroundColor: '#e0f7fa', // Color de fondo para el elemento seleccionado
    color: '#0067A0', // Color del texto para el elemento seleccionado
  },
  '&:hover': {
    backgroundColor: '#b2ebf2', // Color de fondo al pasar el ratón por encima
  },
}));

const MaintenanceCard = styled(Card)({
  position: 'absolute',
  top: '42px', // Ajusta según el tamaño de la AppBar
  width: `calc(100% - ${drawerWidth}px)`, // Ajusta el ancho según el drawerWidth
  maxWidth: `calc(100% - ${drawerWidth}px)`, // Ajusta el ancho máximo según el drawerWidth
  margin: '0 auto',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra ligera
  background: '#f0f0f0', // Fondo de la tarjeta
  zIndex: 999, // Asegura que esté por encima del contenido principal
  padding: '5px',
  textAlign: 'center',
  opacity: 0.9, // Opacidad para hacerla más discreta
});

const MyDrawerComponent = ({ pageName, selectedMenuItem }) => {
  const [open, setOpen] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div>Cargando perfil...</div>; // Muestra un mensaje de carga o algún componente de carga
  }

  return (
    <div>
      <MyAppBar position="fixed">
        <MyToolbar>
          <MyIconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </MyIconButton>
          <PageName>{pageName}</PageName>
          <Notificaciones correo={user.email} />

          <CenteredLogo>
            <Logo src={logo} alt="Logo" />
          </CenteredLogo>
          {isAuthenticated && user ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText style={{ marginRight: '8px' }} primary={user.name || 'User'} />
              <Avatar alt="User" src={user.picture || ''} />
            </div>
          ) : (
            <div>Login to see user details</div>
          )}
        </MyToolbar>
      </MyAppBar>

      <MyDrawer variant="persistent" anchor="left" open={open}>
        <MyDrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <MenuIcon />
          </IconButton>
        </MyDrawerHeader>
        <List>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Proyectos'}>
              <ListItemText primary="Proyectos" />
            </StyledListItem>
          </Link>
          <Link to="/solicitudes" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Solicitudes'}>
              <ListItemText primary="Solicitudes" />
            </StyledListItem>
          </Link>
          <Link to="/post-mortem/CCADDEV" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Post Mortem'}>
              <ListItemText primary="Post Mortem" />
            </StyledListItem>
          </Link>
          <Link to="/resultados-encuestas" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Resultados encuestas'}>
              <ListItemText primary="Estadisticas" />
            </StyledListItem>
          </Link>
          <Link to="/user-stories" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'User Stories'}>
              <ListItemText primary="User Stories" />
            </StyledListItem>
          </Link>
          {/*
          <Link to="/matriz-prioridad" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Matriz prioridad'}>
              <ListItemText primary="Matriz prioridad" />
            </StyledListItem>
          </Link>
          */}
          <Link to="/Sitios-Web" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledListItem button selected={selectedMenuItem === 'Nuestros Servicios'}>
              <ListItemText primary="Nuestros Servicios" />
            </StyledListItem>
          </Link>
        </List>

    


        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
          <LogoutButton /> 
          <ExitToApp />
        </div>
      </MyDrawer>

     

      <MyContent>{/* Contenido de la página */}</MyContent>
    
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
  <Typography variant="body1" align="center">
    La pagina se encuentra mantenimiento y algunas funciones pueden fallar. Disculpe las molestias que esto le pueda causar. 🤒
  </Typography>
</div> */}


    </div>
  );
};

export default MyDrawerComponent;
