import React, { useState, useEffect, useRef } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Autocomplete, Box, Button
} from '@mui/material';
import { createMatriz, GetAllMatriz, updateMatriz } from '../APIS/MatrizPrioridad';

const optionsImpactoEstrategico = [
    { label: '9-10: Impacto crítico, alineado directamente con objetivos estratégicos clave', value: 10 },
    { label: '7-8: Impacto significativo, contribuye de manera importante a los objetivos', value: 8 },
    { label: '5-6: Impacto moderado, apoya objetivos secundarios', value: 6 },
    { label: '3-4: Impacto bajo, no prioritario pero puede tener relevancia futura', value: 4 },
    { label: '1-2: Impacto mínimo o no alineado con objetivos estratégicos', value: 2 },
];

const optionsViabilidadTecnica = [
    { label: '5: Todos los recursos tecnológicos y humanos están disponibles', value: 5 },
    { label: '4: Requiere algunos ajustes menores para ser viable', value: 4 },
    { label: '3: Existen limitaciones técnicas que requieren planificación adicional', value: 3 },
    { label: '2: Viabilidad dudosa, grandes esfuerzos necesarios', value: 2 },
    { label: '1: Inviable con los recursos actuales', value: 1 },
];

const optionsUrgencia = [
    { label: '5: Proyecto con demanda inmediata', value: 5 },
    { label: '4: Necesario a corto plazo (1-3 meses)', value: 4 },
    { label: '3: Prioridad media, puede postergarse', value: 3 },
    { label: '2: Poca urgencia, se considera para el futuro', value: 2 },
    { label: '1: Sin urgencia aparente', value: 1 },
];

const optionsRiesgo = [
    { label: '1: Riesgo bajo o inexistente', value: 1 },
    { label: '2: Riesgo moderado y manejable', value: 2 },
    { label: '3: Riesgo significativo que requiere monitoreo', value: 3 },
    { label: '4: Riesgo alto que podría afectar el éxito', value: 4 },
    { label: '5: Riesgo crítico que pone en peligro el proyecto', value: 5 },
];

const optionsCapacidadEquipo = [
    { label: '5: Equipo completamente disponible y con experiencia adecuada', value: 5 },
    { label: '4: Equipo disponible, pero con limitaciones menores', value: 4 },
    { label: '3: Disponibilidad parcial del equipo, requiere ajuste', value: 3 },
    { label: '2: Disponibilidad limitada, afectará los plazos', value: 2 },
    { label: '1: Equipo no disponible actualmente', value: 1 },
];

const TablaMatriz = ({ initialData, idea, descripcion, userName, onClose }) => {
    const [formValues, setFormValues] = useState({
        nombreProyecto: '',
        descripcion: '',
        responsable: '',
        impactoEstrategico: '',
        viabilidadTecnica: '',
        urgencia: '',
        riesgo: '',
        capacidadEquipo: '',
        puntajeTotal: 0,
        prioridad: '',
        asignacionResponsable: '',
    });

    const [isEdit, setIsEdit] = useState(false);
    const tableRef = useRef(null); 

    useEffect(() => {
        const fetchData = async () => {
            if (initialData) {
                const allProjects = await GetAllMatriz();
                const matchedProject = allProjects.find(project => project.NombreProyecto === idea);

                if (matchedProject) {
                    setFormValues({
                        nombreProyecto: matchedProject.NombreProyecto,
                        descripcion: matchedProject.Descripcion,
                        responsable: matchedProject.Responsable || userName || '',
                        impactoEstrategico: matchedProject.ImpactoEstrategico || '',
                        viabilidadTecnica: matchedProject.ViabilidadTecnica || '',
                        urgencia: matchedProject.Urgencia || '',
                        riesgo: matchedProject.Riesgo || '',
                        capacidadEquipo: matchedProject.CapacidadEquipo || '',
                        puntajeTotal: matchedProject.PuntajeTotal || 0,
                        prioridad: matchedProject.Prioridad || 'Baja',
                        asignacionResponsable: matchedProject.AsignacionResponsable || '',
                    });
                    setIsEdit(true);
                } else {
                    setFormValues(prevValues => ({
                        ...prevValues,
                        nombreProyecto: idea || '',
                        descripcion: descripcion || '',
                        responsable: userName || '',
                        prioridad: 'Baja',
                    }));
                    setIsEdit(false);
                }
            } else {
                setFormValues({
                    nombreProyecto: idea || '',
                    descripcion: descripcion || '',
                    responsable: userName || '',
                    impactoEstrategico: '',
                    viabilidadTecnica: '',
                    urgencia: '',
                    riesgo: '',
                    capacidadEquipo: '',
                    puntajeTotal: 0,
                    prioridad: 'Baja',
                    asignacionResponsable: '',
                });
                setIsEdit(false);
            }
        };

        fetchData();
    }, [initialData, userName, descripcion, idea]);

    const handleChange = (field, value) => {
        const updatedValue = (field === 'impactoEstrategico' || field === 'viabilidadTecnica' || field === 'urgencia' || field === 'riesgo' || field === 'capacidadEquipo') 
        ? (value ? value.value : '') 
        : value;

        const updatedValues = { ...formValues, [field]: updatedValue };

        const valores = {
            impactoEstrategico: updatedValues.impactoEstrategico,
            viabilidadTecnica: updatedValues.viabilidadTecnica,
            urgencia: updatedValues.urgencia,
            riesgo: updatedValues.riesgo,
            capacidadEquipo: updatedValues.capacidadEquipo
        };

        const puntajeTotal = Object.values(valores).reduce((total, item) => {
            return total + (item || 0);
        }, 0);

        updatedValues.puntajeTotal = puntajeTotal;

        if (puntajeTotal >= 20 && puntajeTotal <= 30) {
            updatedValues.prioridad = 'Alta';
        } else if (puntajeTotal >= 11 && puntajeTotal <= 19) {
            updatedValues.prioridad = 'Media';
        } else {
            updatedValues.prioridad = 'Baja';
        }

        setFormValues(updatedValues);
    };

    const handleSave = async () => {
        try {
            const allProjects = await GetAllMatriz();
            const existingProject = allProjects.find((project) => project.NombreProyecto === formValues.nombreProyecto);

            const datos = {
                NombreProyecto: formValues.nombreProyecto,
                Descripcion: formValues.descripcion,
                Responsable: formValues.responsable,
                ImpactoEstrategico: formValues.impactoEstrategico,
                ViabilidadTecnica: formValues.viabilidadTecnica,
                Urgencia: formValues.urgencia,
                Riesgo: formValues.riesgo,
                CapacidadEquipo: formValues.capacidadEquipo,
                PuntajeTotal: formValues.puntajeTotal,
                Prioridad: formValues.prioridad,
                AsignacionResponsable: formValues.asignacionResponsable,
            };

            if (isEdit) {
                await updateMatriz(existingProject.id, datos);
            } else {
                await createMatriz(datos);
            }

            onClose();
        } catch (error) {
            console.error('Error al guardar los datos:', error);
        }
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'Alta':
                return { color: "red" };
            case 'Media':
                return { color: 'orange' }; 
            case 'Baja':
                return { color: 'green' };
            default:
                return {};
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
        });

        if (tableRef.current) {
            resizeObserver.observe(tableRef.current);
        }

        return () => {
            if (tableRef.current) {
                resizeObserver.unobserve(tableRef.current);
            }
        };
    }, [tableRef]);

    if (!initialData && !idea) {
        return <div>Cargando...</div>;
    }

    return (
        <Box sx={{ m: 2 }}>
            <Paper sx={{ padding: 2 }} ref={tableRef}>
                <TableContainer>
                    <Table sx={{ minWidth: 800 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ minWidth: 300 }}>Nombre del Proyecto</TableCell>
                                <TableCell sx={{ minWidth: 500 }}>Descripción</TableCell>
                                <TableCell sx={{ minWidth: 300 }}>Responsable</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Impacto Estratégico</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Viabilidad Técnica</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Urgencia</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Riesgo</TableCell>
                                <TableCell sx={{ minWidth: 220 }}>Capacidad del Equipo</TableCell>
                                <TableCell sx={{ width: '10%' }}>Puntaje Total</TableCell> 
                                <TableCell sx={{ width: '10%' }}>Prioridad</TableCell> 
                                <TableCell sx={{ width: '10%' }}>Asignación Responsable</TableCell> 
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField 
                                        value={formValues.nombreProyecto} 
                                        disabled
                                        fullWidth
                                        multiline
                                        minRows={2}/>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={formValues.descripcion}
                                        disabled
                                        fullWidth
                                        multiline
                                        minRows={4}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField value={formValues.responsable} disabled fullWidth />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={optionsImpactoEstrategico}
                                        getOptionLabel={(option) => option.label}
                                        value={optionsImpactoEstrategico.find(option => option.value === formValues.impactoEstrategico) || null}
                                        onChange={(event, newValue) => handleChange('impactoEstrategico', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Impacto Estratégico" variant="outlined" />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={optionsViabilidadTecnica}
                                        getOptionLabel={(option) => option.label}
                                        value={optionsViabilidadTecnica.find(option => option.value === formValues.viabilidadTecnica) || null}
                                        onChange={(event, newValue) => handleChange('viabilidadTecnica', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Viabilidad Técnica" variant="outlined" />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={optionsUrgencia}
                                        getOptionLabel={(option) => option.label}
                                        value={optionsUrgencia.find(option => option.value === formValues.urgencia) || null}
                                        onChange={(event, newValue) => handleChange('urgencia', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Urgencia" variant="outlined" />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={optionsRiesgo}
                                        getOptionLabel={(option) => option.label}
                                        value={optionsRiesgo.find(option => option.value === formValues.riesgo) || null}
                                        onChange={(event, newValue) => handleChange('riesgo', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Riesgo" variant="outlined" />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={optionsCapacidadEquipo}
                                        getOptionLabel={(option) => option.label}
                                        value={optionsCapacidadEquipo.find(option => option.value === formValues.capacidadEquipo) || null}
                                        onChange={(event, newValue) => handleChange('capacidadEquipo', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Capacidad del Equipo" variant="outlined" />}
                                    />
                                </TableCell>
                                <TableCell sx={{ minWidth: 70 }}>
                                    <TextField value={formValues.puntajeTotal} disabled fullWidth />
                                </TableCell>
                                <TableCell sx={{ minWidth: 120 }}>
                                    <TextField
                                        value={formValues.prioridad}
                                        disabled
                                        fullWidth
                                        InputProps={{
                                            style: { 
                                                color: getPriorityColor(formValues.prioridad).color, 
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ minWidth: 200 }}>
                                    <TextField
                                        value={formValues.asignacionResponsable}
                                        onChange={(e) => handleChange('asignacionResponsable', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        {isEdit ? "Modificar" : "Guardar"}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default TablaMatriz;